import React, { useContext, useState } from "react";
import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import apiClient from "../services/apiclient";
import PageContainer from "../components/PageContainer";
import { Link, useNavigate } from "react-router-dom";
import styles from "./loginPage.module.css";
import { UserContext } from "../contexts/UserContext";

function LoginPage(props) {
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    const body = new FormData();
    body.append("username", username);
    body.append("password", password);

    try {
      const response = await apiClient.post("/token", body);

      // Assuming the response contains the tokens
      const { access_token, refresh_token } = response.data;

      // Save tokens to local storage or cookies
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      // Redirect to a protected route after login
      setIsLoggedIn(true);
      navigate("/dashboard"); // Or wherever you want to redirect
    } catch (error) {
      // Handle error (e.g., show error message)
      setError("Invalid username or password");
      console.error("Login failed:", error);
    }
  }

  return (
    <PageContainer className={styles.background}>
      <Card variant="filled" sx={{ p: 2, width: 0.9, maxWidth: 400 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Login</h2>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <TextField
              id="username"
              label="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Box sx={{ width: 1 ,
              display: "flex",
              flexDirection:"column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
              width: 1,
              justifyContent: "space-between",
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "var(--blue)",
                  "&:focus": { backgroundColor: "var(--blue)" },
                  "&:hover": { backgroundColor: "var(--blue)" },
                  m: 0.2,
                  width: 1,
                }}
                onClick={handleSubmit}
              >
                AANMELDEN
              </Button>
              <Box
              sx={{
                mt: "1em",
                "& a": {
                  textDecoration: "underline",
                  color: "lightgray",

                }
              }}>
                <Link to="/register">
                Registreren
                </Link>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
}

export default LoginPage;
