// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import apiClient from "../services/apiclient";

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    // Global state
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); // Initialize loading state

    useEffect(() => {
        // Check login status on component mount
        apiClient.get("/auth/check")
            .then(response => {
                setIsLoggedIn(true);
            })
            .catch(error => {
                setIsLoggedIn(false);
                console.error("Failed to check user login status:", error);
            })
            .finally(() => {
                // Ensure loading is set to false after the check
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on mount

    return (
        <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, loading, setLoading }}>
            {children}
        </UserContext.Provider>
    );
};
