import styles from "./card.module.css";
import { motion } from "framer-motion";
function Card(props) {
  const animations = {
    initial: { opacity: 0, x: 100, scale: .5 },
    animate: { opacity: 1, x: 0 , scale: 1},
    exit: { opacity: 0, x: -200 },
  };
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: .5,  ease: [0.43, 0.13, 0.23, 0.96]}}
      className={styles.card}
      style={{
        minWidth: props.minWidth,
        minHeight: props.minHeight,
        height: props.height,
      }}
    >
      {props.children}
    </motion.div>
  );
}
export default Card;
