import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Outlet, useLocation, useParams } from "react-router-dom";

function ManagementNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { eventURN } = useParams();
  const eventName = eventURN.toUpperCase().replace(/-/g, " ")

  // Derive base path from current location
  const segments = location.pathname.split("/").filter(Boolean);
  const basePath = segments.slice(0, 2).join("/");
  const isSetup = !!(segments[2] === "setup")
  console.log(segments)
  console.log(isSetup)
  if (isSetup) {
    return <Box
    
  >
    <Outlet />
  </Box>
  }
  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {/* Sidebar */}
      <Box
        sx={{
          position: "fixed", // Sidebar stays fixed
          top: "4em", // Start below navbar (4em offset)
          left: 0,
          width: "15em", // Sidebar width
          height: "100%", // Full height
          backgroundColor: "#f5f5f5", // Sidebar background color
          borderRight: "1px solid #ddd", // Sidebar border
          zIndex: 1000, // Ensure it's above content
        }}
      >
        <Typography
          variant="h5"
          sx={{ width: "100%", textAlign: "left", ml: "1em", mt: "1em" }}
        >
          <strong>{eventName}</strong>
        </Typography>
        {/* Sidebar content can be added here */}
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          position: "relative",
          marginLeft: "15em", // Adjust for sidebar width
          top: "4em", // Offset for navbar
          flexGrow: 1, // Fills remaining width
          padding: "1em",
          width: "100%",
          height: "calc(100vh - 6em)", // Subtract navbar height
          overflowY: "auto", // Scroll if content exceeds viewport height
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default ManagementNavbar;
