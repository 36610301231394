import { TextField, Switch, FormControlLabel, Box } from "@mui/material";

function AddressForm({
  street,
  setStreet,
  number,
  setNumber,
  postalCode,
  setPostalCode,
  city,
  setCity,
  streetError,
  numberError,
  postalCodeError,
  cityError,
  fullAddressEnabled,
  setFullAddressEnabled,
}) {
  const handleToggle = () => {
    setFullAddressEnabled((prev) => !prev);
    setStreet(null);
    setNumber(null);
  };

  const handlePostcodeInput = (event) => {
    const input = event.target.value;
    // Remove non-numeric characters and limit to 4 characters
    const numericInput = input.replace(/\D/g, "").slice(0, 4);
    setPostalCode(numericInput);
  };

  const handleNumberInput = (event) => {
    const input = event.target.value;
    // Match pattern: numbers followed optionally by a single letter
    const validInput = input.match(/^\d+[A-Za-z]?$/);
    if (validInput || input === "") {
      setNumber(validInput ? validInput[0] : "");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        maxWidth: "100em",
        width: "100%",
      }}
    >
      <FormControlLabel
        sx={{ justifyContent: "center" }}
        control={
          <Switch checked={fullAddressEnabled} onChange={handleToggle} />
        }
        label="Volledig adres"
      />

      {fullAddressEnabled && (
        <Box
          sx={{
            display: "flex",
            gap: ".5em",
          }}
        >
          <TextField
            label="Straat"
            variant="outlined"
            sx={{ width: "75%" }}
            onChange={(e) => setStreet(e.target.value)}
            value={street}
            error={!!streetError}
            helperText={streetError}
          />
          <TextField
            label="Nummer"
            variant="outlined"
            sx={{ width: "25%" }}
            onChange={handleNumberInput} // Handle input to restrict format
            value={number}
            error={!!numberError}
            helperText={numberError}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          gap: ".5em",
        }}
      >
        <TextField
          label="Postcode"
          variant="outlined"
          sx={{ width: "25%" }}
          inputProps={{
            maxLength: 4, // Limit to 4 characters
            inputMode: "numeric", // Show numeric keyboard on mobile devices
          }}
          onChange={handlePostcodeInput} // Handle input to restrict characters
          value={postalCode}
          error={!!postalCodeError}
          helperText={postalCodeError}
        />
        <TextField
          label="Stad"
          variant="outlined"
          sx={{ width: "75%" }}
          onChange={(e) => setCity(e.target.value)}
          value={city}
          error={!!cityError}
          helperText={cityError}
        />
      </Box>
    </Box>
  );
}

export default AddressForm;
