import styles from "./pageContainer.module.css";
import { motion } from "framer-motion";

function PageContainer(props) {
    const animations = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 }
    };

    return (
        <motion.div
            initial={animations.initial}
            animate={animations.animate}
            exit={animations.exit}
            className={`${styles.PageContainer} ${props.className}`}
        >
            {props.children}
        </motion.div>
    );
}

export default PageContainer;
