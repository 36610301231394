import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import apiClient from '../services/apiclient';
import { UserContext } from '../contexts/UserContext';
import LoadingScreen from '../components/LoadingScreen';

const PrivateRoutes = ({ scopes = [] }) => {
    const { isLoggedIn, setIsLoggedIn, loading, setLoading } = useContext(UserContext);
    const [delayReached, setDelayReached] = useState()

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await apiClient.get('/auth/check');
                setIsLoggedIn(true);
            } catch (error) {
                setIsLoggedIn(false);
            } finally {
                setLoading(false);
                setTimeout(() => {
                    setDelayReached(true);
                }, 500)
            }
        };

        checkAuth();
    }, [setIsLoggedIn]);

    if (loading && delayReached) {
        return <LoadingScreen />; // Show loading screen while checking auth status
    }

    return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
