import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Select,
  FormControlLabel,
  Switch,
  FormControl,
} from "@mui/material";
import { useEffect } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AnimatedBox from "../AnimatedBox";
import useRegistrationFields from "../../hooks/useRegistrationFields";
function RegistrationFieldsSelector({ direction }) {
  const {
    fields,
    addField,
    handleFieldChange,
    addOption,
    handleOptionChange,
    removeOption,
    removeField,
  } = useRegistrationFields();
  return (
    <AnimatedBox direction={direction}>
      <h3>Selecteer de registratievelden</h3>
      {fields.map(
        (field) =>
          field.visible && (
            <Box
              key={field.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "40em",
                marginBottom: "1em",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "6fr 3fr 1fr", // Adjust grid to 6:3:1 ratio for TextField, Select, and IconButton
                  gap: "1em",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  value={field.label}
                  onChange={(e) =>
                    handleFieldChange(field.id, "label", e.target.value)
                  }
                  disabled={field.unchangeable}
                  error={!!field.error}
                  helperText={field.error}
                />
                <Select
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  value={field.type || "text"}
                  onChange={(e) =>
                    handleFieldChange(field.id, "type", e.target.value)
                  }
                  disabled={field.unchangeable}
                >
                  <MenuItem value="text">tekst</MenuItem>
                  <MenuItem value="number">getal</MenuItem>
                  <MenuItem value="date">datum</MenuItem>
                  <MenuItem value="multipleChoice">meerkeuze</MenuItem>
                  <MenuItem value="boolean">ja/nee</MenuItem>
                  <MenuItem value="image">afbeelding</MenuItem>
                  <MenuItem value="file">document</MenuItem>
                  <MenuItem disabled value="phoneNumber">
                    telefoonnummer
                  </MenuItem>
                  <MenuItem disabled value="idNumber">
                    rijksregisternummer
                  </MenuItem>
                </Select>
                {!field.unremoveable && (
                  <IconButton
                    sx={{
                      justifySelf: "end",
                      ":hover": { color: "red" },
                    }}
                    onClick={() => removeField(field.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </Box>
              {!field.unremoveable && (
                <FormControlLabel
                  label="verplichte vraag"
                  control={
                    <Switch
                      checked={!!field.required}
                      disabled={field.unchangeable}
                      onChange={(e) => {
                        handleFieldChange(
                          field.id,
                          "required",
                          e.target.checked
                        );
                        console.log(e.target.checked)
                        console.log(fields);
                      }}
                    />
                  }
                />
              )}
              {field.type === "multipleChoice" && !field.unchangeable && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    AlignItems: "center",
                    gap: "0.5em",
                    width: "88%",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.multiSelect}
                        onChange={(e) =>
                          handleFieldChange(
                            field.id,
                            "multiSelect",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="meerdere keuzes toestaan"
                  />
                  {field.options.map((option, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      <TextField
                        fullWidth
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(field.id, index, e.target.value)
                        }
                        label={`Optie ${index + 1}`}
                      />
                      {index !== 0 && (
                        <IconButton
                          onClick={() => removeOption(field.id, index)}
                          color="neutral"
                          sx={{
                            gridColumn: "span 2",
                            justifySelf: "end",
                            ":hover": { color: "red" },
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{ width: "40%", marginBottom: "2em" }}
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => addOption(field.id)}
                    >
                      Optie toevoegen
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: ".5em",
        }}
      >
        {fields.map(
          (field) =>
            field.unchangeable &&
            !field.visible && (
              <Button
                key={field.id}
                variant="outlined"
                color="warning"
                startIcon={<AddIcon />}
                onClick={() => handleFieldChange(field.id, "visible", true)}
                sx={{
                  marginBottom: "0.5em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {field.label}
              </Button>
            )
        )}
      </Box>
      <Button
        sx={{ marginTop: "1em" }}
        variant="outlined"
        color="primary"
        onClick={addField}
        startIcon={<AddIcon />}
      >
        Veld toevoegen
      </Button>
      {!fields.filter((field) => field.id === 6)[0].visible && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            textAlign: "center",
          }}
        >
          <WarningIcon />
          <p>
            Het rijksregisternummer is vereist indien u de module IDScan wenst
            te gebruiken
          </p>
        </Box>
      )}
    </AnimatedBox>
  );
}

export default RegistrationFieldsSelector;
