import './App.css'

import { useAxiosInterceptor } from './services/apiclient';
import Navbar from './components/Navbar';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import CreateEventPage from './pages/CreateEventPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import LogoutPage from './pages/LogoutPage';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { nlNL } from '@mui/x-date-pickers/locales';
// eslint-disable-next-line
import { locale } from 'dayjs/locale/nl-be';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion"
import LoadingScreen from './components/LoadingScreen';
import { UserProvider } from './contexts/UserContext';
import PrivateRoutes from './utils/PrivateRoutes';
import ManagementNavbar from './components/ManagementNavbar';
import SetupEventPage from './pages/SetupEventPage';
import HomePage from './pages/HomePage';
function App() {
  useAxiosInterceptor();
  return (
    <UserProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be" localeText={nlNL.components.MuiLocalizationProvider.defaultProps.localeText}>
        <Router>
          <AnimatedRoutes />
        </Router>

      </LocalizationProvider >
    </UserProvider>
  );
}

function AnimatedRoutes() {
  const location = useLocation()
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Navbar />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="confirm-email" element={<ConfirmEmailPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="events/create" element={<CreateEventPage />} />
            <Route path="manage/:eventURN/*" element={<ManagementNavbar />}>
              <Route path="setup" element={<SetupEventPage />} />
            </Route>
          </Route>
          <Route path="logout" element={<LogoutPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}
export default App;
