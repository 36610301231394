import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../services/apiclient";
import InnerBox from "../components/InnerBox";
import PageContainer from "../components/PageContainer";

import { Box, Button, IconButton } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from "@mui/icons-material/People";
import RoomIcon from "@mui/icons-material/Room";
import EventIcon from "@mui/icons-material/Event";
function DashboardPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventRequested, setEventRequested] = useState(false);
  const iconButtonStyle = {
    borderRadius: "1em",
    width: "1.5em",
    height: "1.5em",
    backgroundColor: "lightgray",
    transition: ".5s",
    ":hover": {
      backgroundColor: "#2457FF",
      color: "white",
      transition: ".5s",
    },
  };

  function formatDate(isoDate) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(isoDate).toLocaleDateString("en-GB", options); // 'en-GB' is for dd/mm/yyyy format
  }
  useEffect(() => {
    apiClient
      .get("/users/me")
      .then((response) => {
        setUserData(response.data);
        setEvents(response.data.organized_events);
        setEventRequested(false);
      })
      .catch((error) => {
        console.error("Failed to fetch user data:", error);
      });
  }, []);

  return (
    <PageContainer>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "2em",
          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <h1>DASHBOARD</h1>
        {userData && (
          <p>
            <strong>Welkom terug, {userData.first_name}.</strong>
          </p>
        )}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <InnerBox width="100%" maxWidth="25em">
            <h4>mijn registraties</h4>
            <IconButton sx={iconButtonStyle}>
              <AddIcon />
            </IconButton>
          </InnerBox>

          <InnerBox width="100%" maxWidth="25em">
            <h4>mijn groepen</h4>
            <IconButton sx={iconButtonStyle}>
              <AddIcon />
            </IconButton>
          </InnerBox>
          <InnerBox width="100%" padding="1em" maxWidth="25em">
            <h4>mijn evenementen</h4>
            {!(events.length === 0) &&
              events.map((event) => {
                let statusText;
                switch (event.status) {
                  case "requested":
                    statusText = "aangevraagd";
                    if (!eventRequested) {
                      setEventRequested(true);
                    }
                    break;
                  case "under review":
                    statusText = "wordt beoordeeld";
                    break;
                  case "accepted":
                    statusText = "aanvaard";
                    break;
                  case "denied":
                    statusText = "geweigerd";
                    break;
                  default:
                    statusText = "onbekend";
                }

                const startDate = event.setup_start_date
                  ? formatDate(event.setup_start_date)
                  : formatDate(event.event_start_date);
                const endDate = event.setup_start_date
                  ? formatDate(event.breakdown_end_date)
                  : formatDate(event.event_end_date);
                return (
                  <InnerBox width="90%" padding="1em">
                    <Box
                      sx={{
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1em",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <h3>{event.name}</h3>
                        <Box
                          sx={{
                            padding: ".5em 1em",
                            fontWeight: "bold",
                            color: "White",
                            borderRadius: "5em",
                            backgroundColor: `var(--${event.status})`,
                          }}
                        >
                          {statusText}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "-1em",
                          textAlign: "justify",
                          width: "100%",
                        }}
                      >
                        <p>{event.description}</p>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: ".5em",
                          width: "100%",
                          cursor: "pointer",
                          justifyContent: "flex-start",
                        }}
                      >
                        <PeopleIcon />{" "}
                        {event.capacity < 1000 ? `${event.capacity}` : `1000+`}
                        <RoomIcon />
                        {event.street
                          ? `${event.street} ${event.number}, ${event.postal_code} ${event.city}`
                          : `${event.postal_code} ${event.city}`}
                        <EventIcon />{" "}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            "& p": {
                              margin: "0",
                            },
                          }}
                        >
                          <p>{startDate}</p>
                          <p>{endDate}</p>
                        </Box>
                      </Box>
                      {event.status === "accepted" && (
                        <Box
                          sx={{
                            marginTop: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1em",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          {event.is_managed ? (
                            <Button
                            onClick={() => navigate(`/manage/${event.urn}`)}
                              sx={{
                                width: "70%",
                                backgroundColor: "gray",
                                color: "white",
                              }}
                            >
                              beheren
                            </Button>
                          ) : (
                            <Button
                              onClick={() => navigate(`/manage/${event.urn}/setup`)}
                              variant="contained"
                              sx={{
                                width: "70%",
                                backgroundColor: "var(--green)",
                                ":hover": {
                                  backgroundColor: "var(--green-hover)",
                                },
                              }}
                            >
                              starten
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </InnerBox>
                );
              })}
            {!eventRequested && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/events/create"
                  style={{ width: "auto", textDecoration: "none" }}
                >
                  <IconButton sx={iconButtonStyle}>
                    <AddIcon />
                  </IconButton>
                </Link>
              </Box>
            )}
          </InnerBox>
        </Box>
      </Box>
    </PageContainer>
  );
}

export default DashboardPage;
