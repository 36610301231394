import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import styles from "./ConfirmEmailPage.module.css";
import { Card, CardContent } from "@mui/material";
import apiClient from "../services/apiclient";

import {Button} from "@mui/material";

function ConfirmEmailPage() {
  const [state, setState] = useState("loading");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email_code = urlParams.get("code");
  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await apiClient.get(
          `/confirm-email?code=${email_code}`
        );
        if (response.status === 200) {
          setState("success");
        } else {
          setState("error");
        }
      } catch (error) {
        console.error("Failed to confirm email:", error);
        setState("error");
      }
    };

    confirmEmail();
  }, [email_code]);
  return (
    <PageContainer className={styles.background}>
      <Card variant="filled" sx={{ p: 2, width: 0.9, maxWidth:400, textAlign: "center"}}>
        {state === "loading" && <CardContent>loading...</CardContent>}
        {state === "success" && (
          <CardContent>
            Uw e-mailadres werd successvol bevestigd. U kan nu inloggen op uw
            account.
            <Link to="/login">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e9a224",
                  "&:focus": { backgroundColor: "#e9a224" },
                  "&:hover": { backgroundColor: "#e9a224" },
                  m: 0.2,
                  width: 1,
                }}
              >
                AANMELDEN
              </Button>
            </Link>
          </CardContent>
        )}
        {state === "error" && <CardContent>Deze code is ongeldig of reeds gebruikt. Het account is mogelijks al geactiveerd. Probeer in te loggen. Indien het probleem zich blijft stellen, kan u contact opnemen met een systeembeheerder.</CardContent>}
      </Card>
    </PageContainer>
  );
}
export default ConfirmEmailPage;
