import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout Icon
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, loading, setLoading } =
    useContext(UserContext); // Assume logged in for this example

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  if (loading) {
    return null; // You can return a loading indicator here if desired
  } else {
    return (
      <Box
        sx={{
          position: "relative",
          left: 0,
          right: 0,
          top: 0,
          height: "auto",
          zIndex: 1200, // Ensure the navbar is above other content
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            backgroundColor: "#1F1F1F",
            height: "4em",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" }, // Center on small screens, space-between on medium and up
            zIndex: 1200, // Ensure the navbar is above other content
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
        >
          {/* Burger Menu Icon */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              padding: ".2em",
              border: "1px solid white",
              borderRadius: ".2em",
              position: "absolute",
              left: 20,
              display: { xs: "flex", md: "none" }, // Show on small screens
            }}
          >
            <MenuIcon htmlColor="white" />
          </IconButton>

          {/* Logo */}
          <Box
            component="img"
            src="/logo2.svg" // Path to your logo
            alt="Logo"
            sx={{
              marginLeft: { xs: 0, md: "2em" },
              height: "2.5em", // Adjust logo size as needed
              display: { xs: "block", md: "block" }, // Show on all screens
            }}
          />

          {/* Navigation Links for Larger Screens */}
          <Box
            sx={{
              color: "white",
              display: { xs: "none", md: "flex" }, // Show only on medium and larger screens
              gap: "2em", // Space between links
              marginRight: "2em",
              alignItems: "center", // Align profile items vertically
            }}
          >
            <Link to="/events">
              <Button color="inherit">Events</Button>
            </Link>
            {/* Always visible */}
            {isLoggedIn ? (
              <>
                <Link to="/dashboard">
                  <Button color="inherit">Dashboard</Button>
                </Link>
                {/* Profile Section */}
                <Link to="/profile">
                  <Button color="inherit">Profile</Button>
                </Link>
                {/* Logout Icon Button */}
                <Link to="/logout">
                  <IconButton color="inherit" sx={{ ml: 1 }}>
                    <LogoutIcon />
                  </IconButton>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button variant="outlined" color="inherit">
                    Aanmelden
                  </Button>
                </Link>
                <Link to="/register">
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    color="inherit"
                  >
                    Registreren
                  </Button>
                </Link>
              </>
            )}
          </Box>
        </Box>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: "block", md: "none" }, // Show on small screens
          }}
        >
          <Box
            sx={{
              width: 250, // Width of the drawer
              height: "100%",
              backgroundColor: "#1F1F1F",
              color: "white",
              padding: "1em",
              "& a": {
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "none",
                },
              },
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: "1em" }}>
              Voluntex
            </Typography>
            <List>
              <Link to="/events">
                <ListItem onClick={handleDrawerClose}>
                  <ListItemButton>
                    <ListItemText primary="Events" />
                  </ListItemButton>
                </ListItem>
              </Link>
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/profile">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Profile" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/logout">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <LogoutIcon sx={{ marginRight: "1em" }} />
                        <ListItemText primary="Logout" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Aanmelden" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/register">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Registreren" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              )}
            </List>
          </Box>
        </Drawer>
        <Outlet />
      </Box>
    );
  }
}
export default Navbar;
