import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
    }
});

// Custom hook to handle navigation
export const useAxiosInterceptor = () => {

    apiClient.interceptors.request.use((config) => {
        const token = localStorage.getItem('access_token');
        console.log(token);

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    apiClient.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refresh_token');
                if (!refreshToken) throw new Error('No refresh token available');

                const response = await axios.post(`${baseURL}/refresh-token`, { refresh_token: refreshToken });

                const newAccessToken = response.data.access_token;
                const newRefreshToken = response.data.refresh_token;
                localStorage.setItem('access_token', newAccessToken);
                localStorage.setItem('refresh_token',  newRefreshToken);
                // Update the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

                return apiClient(originalRequest); // Retry the original request
            } catch (err) {
                console.error('Token refresh failed:', err);
                // localStorage.removeItem('access_token');
                // localStorage.removeItem('refresh_token');
                console.log("returning to login page") // Navigate to login page on failure
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    });
};

export default apiClient;
