import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { Box } from "@mui/material";
function HomePage() {
    const { isLoggedIn, setIsLoggedIn} = useContext(UserContext);
    
    return (
        <Box>
        {isLoggedIn ? (
            <Navigate to="/dashboard"/>
        ):( <Navigate to="/login"/>)}
        </Box>
    )

}
export default HomePage;