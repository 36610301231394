import React from "react";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import RemoveIcon from '@mui/icons-material/Remove';

function DateRangePicker({
  startDate, 
  setStartDate, 
  endDate, 
  setEndDate,
  minStartDate,
  maxEndDate,
  startLabel = "Start Date",
  endLabel = "End Date",
  startError,
  endError,
  disabled
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger screens
        gap: 2,
        width: '100%', // Full width to ensure responsiveness
        alignItems: 'center',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DatePicker
          slotProps={{
            textField: {
              id: "start-date",
              error: !!startError,
              helperText: startError,
            },
          }}
          label={startLabel}
          format="DD/MM/YYYY"
          views={["year", "month", "day"]}
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          disablePast
          minDate={minStartDate}
          maxDate={endDate}
          sx={{ width: '100%' }}
          disabled={disabled}
        />
      </Box>
      <RemoveIcon sx={{ display: { xs: "none", sm: "flex" }, paddingTop: ".5em" }} htmlColor="black" />
      <Box sx={{ flex: 1 }}>
        <DatePicker
          slotProps={{
            textField: {
              id: "end-date",
              error: !!endError,
              helperText: endError,
            },
          }}
          label={endLabel}
          format="DD/MM/YYYY"
          views={["year", "month", "day"]}
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          disablePast
          minDate={startDate}
          maxDate={maxEndDate}
          sx={{ width: '100%' }}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}

export default DateRangePicker;
