import { Box } from "@mui/material"
import { motion } from "framer-motion"
function AnimatedBox({ direction, children }) {
    const animations = {
      next: {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
      },
      prev: {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
      },
    };
  
    const stepBoxSX = {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "left", 
    };
  
    return (
      <Box
        sx={stepBoxSX}
        component={motion.div}
        variants={animations[direction]}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.3 }}
      >
        {children}
      </Box>
    );
  }

export default AnimatedBox;