import { useState, useEffect } from "react";

const defaultFields = [
  {
    id: 1,
    label: "voornaam",
    type: "text",
    unchangeable: true,
    unremoveable: true,
    visible: true,
    error: "",
  },
  {
    id: 2,
    label: "achternaam",
    type: "text",
    unchangeable: true,
    unremoveable: true,
    visible: true,
    error: "",
  },
  {
    id: 3,
    label: "gender",
    type: "multipleChoice",
    options: ["man", "vrouw", "anders", "zeg ik liever niet"],
    unchangeable: true,
    unremoveable: true,
    visible: true,
    error: "",
  },
  {
    id: 4,
    label: "e-mailadres",
    type: "text",
    unchangeable: true,
    unremoveable: true,
    visible: true,
    error: "",
  },
  {
    id: 5,
    label: "telefoonnummer",
    type: "phoneNumber",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
  },
  {
    id: 6,
    label: "geboortedatum",
    type: "date",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
  },
  {
    id: 7,
    label: "rijksregisternummer",
    type: "idNumber",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
  },
  {
    id: 8,
    label: "allergenen",
    type: "text",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
  },
  {
    id: 9,
    label: "portretfoto",
    type: "image",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
  },
  // Address fields
  {
    id: 10,
    label: "straat",
    type: "text",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
    group: "adres",
  },
  {
    id: 11,
    label: "huisnummer",
    type: "number",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
    group: "adres",
  },
  {
    id: 12,
    label: "postcode",
    type: "number",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
    group: "address",
  },
  {
    id: 13,
    label: "stad",
    type: "text",
    unchangeable: true,
    unremoveable: false,
    visible: false,
    error: "",
    group: "address",
  },
];

function useRegistrationFields() {
  const [fields, setFields] = useState(defaultFields);
  const [adressRequired, setAdressRequired] = useState(false);

  // Add address fields collectively
  function addAddressFields() {
    const addressFields = defaultFields.filter(field => field.group === "adres");
    setFields(prevFields => [
      ...prevFields,
      ...addressFields.filter(field => !prevFields.some(f => f.label === field.label))
    ]);
  }

  // Remove address fields collectively
  function removeAddressFields() {
    setFields(prevFields =>
      prevFields.filter(field => field.group !== "address")
    );
  }

  // Add a new field
  function addField() {
    setFields(prevFields => [
      ...prevFields,
      {
        id: Date.now(), // Unique ID for new fields
        label: "nieuw veld",
        type: "text",
        options: ["", "", ""],
        required: true,
        unchangeable: false,
        unremoveable: false,
        multiSelect: false,
        visible: true,
        error: "",
      },
    ]);
  }

  // Update a field
  function handleFieldChange(id, field, value) {
    setFields(prevFields =>
      prevFields.map(fieldItem =>
        fieldItem.id === id
          ? { ...fieldItem, [field]: value, error: "" }
          : fieldItem
      )
    );
  }

  // Add an option to a field
  function addOption(fieldId) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? { ...field, options: [...field.options, ""] }
          : field
      )
    );
  }

  // Handle option change
  function handleOptionChange(fieldId, optionIndex, newValue) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? {
            ...field,
            options: field.options.map((option, idx) =>
              idx === optionIndex ? newValue : option
            ),
          }
          : field
      )
    );
  }

  // Remove an option from a field
  function removeOption(fieldId, optionIndex) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? {
            ...field,
            options: field.options.filter((_, idx) => idx !== optionIndex),
          }
          : field
      )
    );
  }

  // Remove a field
  function removeField(id) {
    const field = fields.find(field => field.id === id);
    if (field?.unchangeable) {
      handleFieldChange(field.id, "visible", false);
      return;
    }
    setFields(prevFields => prevFields.filter(field => field.id !== id));
  }

  return {
    fields,
    setFields,
    adressRequired,
    setAdressRequired,
    addAddressFields,
    removeAddressFields,
    addField,
    handleFieldChange,
    addOption,
    handleOptionChange,
    removeOption,
    removeField
  };
}

export default useRegistrationFields;
